<template>
	<div class="form-controls position-relative w-100 p-2 d-flex justify-content-between">
		<div class="container-buttons position-relative w-auto h-100 d-flex justify-content-center">
			<slot name="left"></slot>
<!--			<button class="button-rounded w-auto btn mr-2 d-flex align-items-center"
			        type="button"
			        v-for="({ name, icon, disabled, classBinding }, index) in leftButtons"
			        v-bind:key="name"
			        v-bind:disabled="disabled"
			        v-bind:class="classBinding"
			        v-on:click="$emit('left-button-pressed', index)">
				<font-awesome-icon v-if="icon" class="icon mr-2"
				                   v-bind:icon="icon">
				</font-awesome-icon>
				<span class="name font-weight-bold text-truncate">{{name}}</span>
			</button>-->
		</div>
		<div class="container-messages position-relative w-auto h-100 d-flex justify-content-center align-items-center flex-grow-1">
			<h4 class="message position-relative w-auto h-100 m-0 text-truncate"
			    v-bind:class="{'text-danger': msgInvalid}"
			    v-bind:title="msg">{{msg}}</h4>
		</div>
		<div class="container-buttons position-relative w-auto h-100 d-flex justify-content-center">
			<slot name="right"></slot>
<!--			<button class="button-rounded w-auto btn ml-2 d-flex align-items-center"
			        type="button"
			        v-for="({ name, icon, disabled, classBinding }, index) in rightButtons"
			        v-bind:key="name"
			        v-bind:disabled="disabled"
			        v-bind:class="classBinding"
			        v-on:click="$emit('right-button-pressed', index)">
				<font-awesome-icon v-if="icon" class="icon mr-2"
				                   v-bind:icon="icon">
				</font-awesome-icon>
				<span class="name font-weight-bold text-truncate">{{name}}</span>
			</button>-->
		</div>
	</div>
</template>

<script>
export default {
	name: "FormControls",
	
	props: {
		/*leftButtons: {
			type: Array,
			required: true
		},
		rightButtons: {
			type: Array,
			required: true
		},*/
		msg: {
			type: String,
			required: true,
		},
		msgInvalid: {
			type: Boolean,
			required: true,
		}
	}
}
</script>

<style lang="scss" scoped>
.form-controls {
	height: 50px;
	
	.container-buttons {
		min-width: 0;
		
		.button-rounded {
			border-radius: 100px;
			min-width: 0;
		}
	}
	
	.container-messages {
		min-width: 0;
		user-select: none;
	}
}
</style>