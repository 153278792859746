<template>
	<div class="control-button position-relative w-auto h-100 d-flex justify-content-center">
		<button type="button" class="button-rounded btn w-auto d-flex align-items-center"
		        v-bind:disabled="disabled"
		        v-bind:class="btnClass"
		        v-on:click="$emit('btn-pressed')">
			<font-awesome-icon v-if="btnIcon.length > 0" class="icon mr-2"
			                   v-bind:icon="btnIcon">
			</font-awesome-icon>
			<span class="name font-weight-bold text-truncate">{{btnTitle}}</span>
		</button>
	</div>
</template>

<script>
export default {
	name: "ControlButton",
	
	props: {
		disabled: {
			type: Boolean,
			required: true
		},
		btnTitle: {
			type: String,
			required: true
		},
		btnIcon: {
			type: Array,
			required: true
		},
		btnClass: {
			type: String,
			required: true
		}
	}
}
</script>

<style lang="scss" scoped>
.control-button {
	min-width: 0;
	
	.button-rounded {
		border-radius: 100px;
		min-width: 0;
	}
}
</style>